<template>
    <ul class="groups">
        <li
            v-for="(item, index) in groups"
            :key="index"
            :class="{ active: iscur === index }"
            @click="changeOrg(index)"
        >
            {{ item.groupName }}
        </li>
    </ul>
</template>

<script>
export default {
    name: 'OrgsTab',
    props: {
        groups: {
            type: Array,
            default: null
        }
    },
    components: {},
    data() {
        return {
            iscur: 0
        }
    },
    created() {},
    methods: {
        changeOrg(index) {
          console.log(index);
          this.iscur = index
          this.$emit('groupChange',index)
        }
    }
}
</script>
<style lang="less" scoped>
.groups {
    display: flex;
    padding-left: 30px;
    margin-top: 15px;
    li {
        padding: 10px 0;
        margin: 0 20px;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        color: #a7b6c3;
        cursor: pointer;
    }
    li.active {
        position: relative;
        color: #3595e1;
    }
    li.active::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 90%;
        height: 3px;
        content: '';
        background: #3595e1;
        border-radius: 2px;
        transform: translateX(-50%);
    }
}
</style>
